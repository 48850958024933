import * as React from "react";
import { Layout, Seo } from "../components";
import "../styles/screen.scss";
import { Heading, Flex, Text } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";

const seoBundle = {
  title: "American Storage",
  subtitle: "About",
  description:
    "American Storage is a family-run business local to Gooding, Idaho",
  slug: "/about",
};

const AboutPage = ({ location }) => {
  return (
    <Layout title={"Home"} location={location}>
      <Seo seoBundle={seoBundle} />
      <Flex
        maxW='full'
        h='100%'
        // header takes up 40/50 pixels
        pt={["150px", null, "150px"]}
        px={["20px", null, "100px"]}
        pb={["60px", null, "100px"]}
        bg={"pink"}
        align='center'
        justify='center'
        flexDirection='column'
      >
        <Heading
          as='h1'
          color='dkBlue'
          mb={["20px", null, null, "10px"]}
          mt='0px'
          fontWeight={800}
          fontSize={["3em", "4em"]}
          fontFamily='Lexend, sans-serif'
          lineHeight={["100%", null, null, "120%"]}
          textAlign='center'
        >
          About
        </Heading>
        <Flex
          mb='40px'
          mt='20px'
          flexDirection='row'
          justify='center'
          align='space-around'
        >
          <Flex
            flexDirection={["column", "row"]}
            justify='space-around'
            align='space-around'
          >
            <StaticImage
              src='../images/tilt.jpg'
              alt='American Storage Facility'
              placeholder='blurred'
              layout='constrained'
              width={[300]}
            />
            <StaticImage
              src='../images/fac.jpg'
              alt='American Storage Facility'
              placeholder='blurred'
              layout='constrained'
              width={[300]}
            />
            <StaticImage
              src='../images/boxes.jpg'
              alt='American Storage Facility'
              placeholder='blurred'
              layout='constrained'
              width={[300]}
            />
          </Flex>
        </Flex>
        <Text
          mt='20px'
          mb='7'
          textAlign='left'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          American Storage is a locally owned and operated business in Gooding,
          Idaho currently servicing the Magic Valley. We offer a variety of
          self-storage solutions for individuals and businesses. We have several
          different unit sizes available at our facility with plenty of space to
          maneuver vehicles and trailers for close, easy access to units.
        </Text>
        <Text
          mt='20px'
          mb='7'
          textAlign='left'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          American Storage also offers portable on-site units giving you the
          convenience of extra storage delivered to your own location. Portable
          units currently available are <strong>8' x 16'</strong>, which by
          comparison is approximately <strong>3 to 4</strong> rooms of
          furniture. Plans for additional sizes are in process.
        </Text>
        <Text
          mt='20px'
          mb='7'
          textAlign='left'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          We have personal face to face assistance available 12 hours a day
          Monday through Friday, eliminationg the frustration of unanswered
          calls and phone messages. We strive and take pride in fast, efficient
          delivery and customer service.
        </Text>
        <Text
          mb='40px'
          mt='20px'
          fontSize={24}
          textAlign='center'
          maxW='800px'
          lineHeight='120%'
          fontWeight='bold'
        >
          Call us at (208) 539-3383
        </Text>
      </Flex>
    </Layout>
  );
};

export default AboutPage;
